import { observer } from "mobx-react-lite";
import { FC, useEffect, useState } from "react";
import { ReleasedPageWrapper } from "./Released.presents";
import { useRootStore } from "../../stores/storeContext";
import { ETReleasedListHeader } from "../../components/et-released-list-header";
import { ETReleasedListTable } from "../../components/et-released-list-table";
import { ETReportModal } from "../../components/et-report-modal";

export const ReleasedPage: FC = observer(() => {
  const {
    getTotalCount,
    fetchFunctionalDemoLocationOptions
  } = useRootStore().releasedListStore;
  const { setGlobalSpiner } = useRootStore().globalStore;

  const { show: showReport } = useRootStore().reportStore;

  const [headerHeight, setHeaderHeight] = useState(0);

  const updateSize = () => {
    let elHeight = document.querySelector(".margin-table table thead")?.getBoundingClientRect()?.bottom || 1;
    if (elHeight !== headerHeight) {
      setHeaderHeight(elHeight);
    }
  };

  useEffect(() => { 
    window.addEventListener("resize", updateSize);
    return () => {
      window.removeEventListener("resize", updateSize);
    };
  }, []);

  useEffect(() => {
    updateSize();
  });

  
  useEffect(() => {
    setGlobalSpiner(true);
    fetchFunctionalDemoLocationOptions();
    getTotalCount().then(() => { setGlobalSpiner(false); });
  }, []);

  return (
    <>
      <ReleasedPageWrapper  headerHeight={headerHeight}>
        <ETReleasedListHeader></ETReleasedListHeader>
        <ETReleasedListTable></ETReleasedListTable>
      </ReleasedPageWrapper>
      {showReport && <ETReportModal></ETReportModal>}
    </>
    
  );
});
import { observer } from "mobx-react-lite";
import { FC, useEffect, useState } from "react";
import { AdminDevicesPageWrapper } from "./Devices.presents";
import { useRootStore } from "../../../stores/storeContext";
import { ETAdminDevicesListHeader } from "../../../components/et-admin-devices-list-header";
import { ETAdminDevicesListTable } from "../../../components/et-admin-devices-list-table";
import { ETRightForm } from "../../../components/et-right-form";
import { ETAdminDevicesEdit } from "../../../components/et-admin-devices-edit";
import { ETReportModal } from "../../../components/et-report-modal";

export const AdminDevicesPage: FC = observer(() => {
  const { getTotalCount } = useRootStore().adminDevicesListStore;
  const { setGlobalSpiner } = useRootStore().globalStore;
  const { show } = useRootStore().adminDevicesEditStore;

  const { show: showReport } = useRootStore().reportStore;

  const [headerHeight, setHeaderHeight] = useState(0);

  const updateSize = () => {
    let elHeight = document.querySelector(".margin-table table thead")?.getBoundingClientRect()?.bottom || 1;
    if (elHeight !== headerHeight) {
      setHeaderHeight(elHeight);
    }
  };

  useEffect(() => { 
    window.addEventListener("resize", updateSize);
    return () => {
      window.removeEventListener("resize", updateSize);
    };
  }, []);

  useEffect(() => {
    updateSize();
  });

  useEffect(() => {
    setGlobalSpiner(true);
    getTotalCount().then(() => { setGlobalSpiner(false); });
  }, []);
  return (
    <>
      <AdminDevicesPageWrapper headerHeight={headerHeight}>
        <ETAdminDevicesListHeader></ETAdminDevicesListHeader>
        <ETAdminDevicesListTable></ETAdminDevicesListTable>
      </AdminDevicesPageWrapper>
      
      {show && <ETRightForm 
        title={"Edit Device"}
      >
        <ETAdminDevicesEdit></ETAdminDevicesEdit>
      </ETRightForm>}

      {showReport && <ETReportModal></ETReportModal>}
    </>
    
  );
});
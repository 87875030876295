import { observer } from "mobx-react-lite";
import { FC } from "react";
import { useRootStore } from "../../stores/storeContext";
import { 
  VQIcon, 
  VQIconSort, 
  VQNoResult, 
  VQSearchDropdown, 
  VQTableLoader, 
  VQTableSection, 
  VQTooltip
} from "vivaquant-components-library";
import { 
  InStockLocationOptions, 
  PAGINATION, 
  ReleasedCategoriesValue, 
  ReleasedListSort, 
  SortDirection 
} from "../../boot/constants";
import SortListIcon from "../../assets/icons/sort-list.svg";
import ReporteIcon from "../../assets/icons/report.svg";
import DeleteIcon from "../../assets/icons/delete.svg";
import WI059Icon from "../../assets/icons/wi-059.svg";
import { IReleasedModel } from "../../stores/released-list.store";
import { ReleasedDeviceStage, ReleasedDeviceStageLabels } from "../../boot/enums/ReleasedDeviceStage";
import { DateUtils, StringsUtils } from "../../services/utils";
import { OptionType } from "../../models/OptionType";
import _uniqueId from "lodash.uniqueid";
import { Permissions } from "../../services/permission.service";
import { confirmAlert } from "react-confirm-alert";

export const ETReleasedListTable: FC = observer(() => {
  const {
    items,
    isLoading,
    activeCategory,
    setSortBy,
    dropDownFilterInstockLocation,
    setSearchInstockLocationValue,
    setDropDownFilterInstockLocation,
    searchInstockLocationValue,

    dropDownFilterFunctionalDemoLocation,
    setSearchFunctionalDemoLocationValue,
    setDropDownFilterFunctionalDemoLocation,
    searchFunctionalDemoLocationValue,
    functionalDemoLocationOptions
  } = useRootStore().releasedListStore;
  const { setGlobalSpiner, isOpenedRightForm } = useRootStore().globalStore;
  const { openModal, getReport } = useRootStore().reportStore;

  const { hasPermission } = useRootStore().userStore;
  const isReportRead = hasPermission(Permissions.ReportRead);


  const sort = (sortBy: string, sortDirection: number) => {
    return setSortBy(sortBy, sortDirection);
  };

  const showInAll = () => {
    return activeCategory === ReleasedCategoriesValue.all;
  };
  const showInStock= () => {
    return activeCategory === ReleasedCategoriesValue.inStock;
  };
  const showInShipped = () => {
    return activeCategory === ReleasedCategoriesValue.shipped;
  };
  const showInDemo = () => {
    return activeCategory === ReleasedCategoriesValue.functionalDemo;
  };

  const onWI059 = (id: string) => {
    console.log(id);
  };

  const onReport = (id: string, serialNumber: string, date: string) => {
    setGlobalSpiner(true);
    return getReport(id, StringsUtils.reportName(serialNumber, date))
      .then((msg: string) => {
        if (msg) {
          confirmAlert({
            message: msg,
            overlayClassName: "sl-warning-modal",
            buttons: [
              {
                label: "Ok",
                onClick: () => {}
              }
            ]
          });
        } else {
          openModal();
        }
        
        setGlobalSpiner(false);
      });
  };

  const onDecommision = (id: string) => {
    console.log(id);
  };

  const getInstockLocationOptions = (): OptionType[] => {
    return InStockLocationOptions.filter((el: any) => {
      if (searchInstockLocationValue) {
        return el.label.toLowerCase().includes(searchInstockLocationValue.toLowerCase());
      }
      return el;
    })
      .map((el: any) => {
        return { label: el.label, value: el.value.toString(), id: el.value.toString() };
      });
  };

  const getFunctionalDemoLocationOptions = (): OptionType[] => {
    return functionalDemoLocationOptions.filter((el: any) => {
      if (searchFunctionalDemoLocationValue) {
        return el.label.toLowerCase().includes(searchFunctionalDemoLocationValue.toLowerCase());
      }
      return el;
    })
      .map((el: any) => {
        return { label: el.label, value: el.value.toString(), id: el.value.toString() };
      });
  };


  const getContent = () => {
    if (isLoading) {    
      return (
        <VQTableLoader
          columns={activeCategory === ReleasedCategoriesValue.all ? 8 : 4}
          rows={PAGINATION.ROWS_PER_PAGE}/>
      );
    }

    return (
      <tbody>
        {items.map((item: IReleasedModel, index: number) => {
          return (
            <tr key={index}>
              <td>
                <div className="td-item">
                  {item.serialNumber || "-"}
                </div>
              </td>
              {showInAll() && <td>
                <div className="td-item">
                  {ReleasedDeviceStageLabels[ReleasedDeviceStage[item.stage] as keyof typeof ReleasedDeviceStageLabels]}
                </div>
              </td>}
              {(showInAll() || showInStock() || showInShipped()) && <td>
                <div className="td-item">
                  {item.realesedAt ? DateUtils.formatDate(item.realesedAt) : "-"}
                </div>
              </td>}
              {(showInAll() || showInStock() ) && <td>
                <div className="td-item">
                  {item.inStockLocation || "-"}
                </div>
              </td>}
              {(showInAll() || showInShipped()) && <td>
                <div className="td-item">
                  {item.shippedAt ? DateUtils.formatDate(item.shippedAt) : "-"}
                </div>
              </td>}
              {(showInAll() || showInDemo()) && <td>
                <div className="td-item">
                  {item.dispositionedAt ? DateUtils.formatDate(item.dispositionedAt) : "-"}
                </div>
              </td>}
              {(showInAll() || showInDemo()) && <td>
                <div className="td-item">
                  {item.functionalDemoLocation || "-"}
                </div>
              </td>}
              <td className="action">
                <div className="td-item">
                  <div className="td-item actions-wrappwer" style={{minWidth: "120px"}}>
                    <div
                      className={"action-item"}
                      onClick={() => { onWI059(item.id); }}>
                      <VQTooltip
                        id={`tooltip-${_uniqueId()}`}
                        class="vq-tooltip"
                        tooltipBlock={<div>Edit</div>}>
                        <VQIcon 
                          icon={WI059Icon}
                          padding="0 8px"/>
                      </VQTooltip>
                    </div>
                    <div
                      className={`action-item ${!isOpenedRightForm && isReportRead && item.report ? "" : "disabled"}`}
                      onClick={() => { onReport(item.report.id, item.serialNumber, item.report.updatedAt); }}>
                      <VQTooltip
                        id={`tooltip-${_uniqueId()}`}
                        class="vq-tooltip"
                        tooltipBlock={<div>Open Device Report</div>}>
                        <VQIcon 
                          icon={ReporteIcon}
                          padding="0 8px"/>
                      </VQTooltip>
                    </div>
                    <div
                      className={"action-item"}
                      style={{margin: "0"}}
                      onClick={() => { onDecommision(item.id); }}>
                      <VQTooltip
                        id={`tooltip-${_uniqueId()}`}
                        class="vq-tooltip"
                        tooltipBlock={<div>Delete</div>}>
                        <VQIcon 
                          icon={DeleteIcon}
                          padding="0 8px"/>
                      </VQTooltip>
                    </div>
                  </div>
                </div>
              </td>
            </tr>
          );
        })}
      </tbody>
    );
  };

  return (
    <div className="margin-table scroll-table">
      <VQTableSection>
        <thead>
          <tr>
            <th>
              <div className="sort">
                <div className="sort-actions">
                  <div onClick={() => sort(ReleasedListSort.serialnumber, SortDirection.ASC)}>
                    <VQIconSort icon={SortListIcon}></VQIconSort>
                  </div>
                  <div onClick={() => sort(ReleasedListSort.serialnumber, SortDirection.DESC)} className="bottom-sort">
                    <VQIconSort icon={SortListIcon}></VQIconSort>
                  </div>
                </div>
                <div className="sort-title">Serial Number</div>
              </div>
            </th>
            {showInAll() && <th>
              <div className="sort">
                <div className="sort-actions">
                  <div onClick={() => sort(ReleasedListSort.stage, SortDirection.ASC)}>
                    <VQIconSort icon={SortListIcon}></VQIconSort>
                  </div>
                  <div onClick={() => sort(ReleasedListSort.stage, SortDirection.DESC)} className="bottom-sort">
                    <VQIconSort icon={SortListIcon}></VQIconSort>
                  </div>
                </div>
                <div className="sort-title">Stage</div>
              </div>
            </th>}
            {(showInAll() || showInStock() || showInShipped()) && <th>
              <div className="sort">
                <div className="sort-actions">
                  <div onClick={() => sort(ReleasedListSort.releasedat, SortDirection.ASC)}>
                    <VQIconSort icon={SortListIcon}></VQIconSort>
                  </div>
                  <div onClick={() => sort(ReleasedListSort.releasedat, SortDirection.DESC)} className="bottom-sort">
                    <VQIconSort icon={SortListIcon}></VQIconSort>
                  </div>
                </div>
                <div className="sort-title">Date Released</div>
              </div>
            </th>}
            {(showInAll() || showInStock() ) && <th>
              <div className="sort no-chevron">
                <div className="sort-actions">
                  <div onClick={() => sort(ReleasedListSort.instocklocation, SortDirection.ASC)}>
                    <VQIconSort icon={SortListIcon}></VQIconSort>
                  </div>
                  <div onClick={() => sort(ReleasedListSort.instocklocation, SortDirection.DESC)}
                    className="bottom-sort">
                    <VQIconSort icon={SortListIcon}></VQIconSort>
                  </div>
                </div>
                <VQSearchDropdown
                  isLoading={false}
                  name={"In Stock Location"}
                  value={dropDownFilterInstockLocation}
                  options={getInstockLocationOptions()}
                  setSearch={setSearchInstockLocationValue}
                  setSelectedOption={setDropDownFilterInstockLocation}
                  searchValue={searchInstockLocationValue}/>
              </div>
            </th>}
            {(showInAll() || showInShipped()) && <th>
              <div className="sort">
                <div className="sort-actions">
                  <div onClick={() => sort(ReleasedListSort.shippedat, SortDirection.ASC)}>
                    <VQIconSort icon={SortListIcon}></VQIconSort>
                  </div>
                  <div onClick={() => sort(ReleasedListSort.shippedat, SortDirection.DESC)} className="bottom-sort">
                    <VQIconSort icon={SortListIcon}></VQIconSort>
                  </div>
                </div>
                <div className="sort-title">Date Shipped</div>
              </div>
            </th>}
            {(showInAll() || showInDemo()) && <th>
              <div className="sort">
                <div className="sort-actions">
                  <div onClick={() => sort(ReleasedListSort.dispositionedat, SortDirection.ASC)}>
                    <VQIconSort icon={SortListIcon}></VQIconSort>
                  </div>
                  <div onClick={() => sort(ReleasedListSort.dispositionedat, SortDirection.DESC)}
                    className="bottom-sort">
                    <VQIconSort icon={SortListIcon}></VQIconSort>
                  </div>
                </div>
                <div className="sort-title">Functional Demo Date</div>
              </div>
            </th>}
            {(showInAll() || showInDemo()) && <th>
              <div className="sort no-chevron">
                <div className="sort-actions">
                  <div onClick={() => sort(ReleasedListSort.functionaldemolocation, SortDirection.ASC)}>
                    <VQIconSort icon={SortListIcon}></VQIconSort>
                  </div>
                  <div onClick={() => sort(ReleasedListSort.functionaldemolocation, SortDirection.DESC)}
                    className="bottom-sort">
                    <VQIconSort icon={SortListIcon}></VQIconSort>
                  </div>
                </div>
                <VQSearchDropdown
                  isLoading={false}
                  name={"Functional Demo Location"}
                  value={dropDownFilterFunctionalDemoLocation}
                  options={getFunctionalDemoLocationOptions()}
                  setSearch={setSearchFunctionalDemoLocationValue}
                  setSelectedOption={setDropDownFilterFunctionalDemoLocation}
                  searchValue={searchFunctionalDemoLocationValue}/>
              </div>
            </th>}
            <th className="action">Actions</th>
          </tr>
        </thead>
        {isLoading && getContent()}
        {items.length > 0 && !isLoading && getContent()}
      </VQTableSection>
      {items.length === 0 && !isLoading && <VQNoResult/> }
    </div>
  );
});
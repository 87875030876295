import styled from "styled-components";
import { theme } from "../../../theme/theme";

export const AdminRouteConfigurationPageWrapper = styled.div<{headerHeight: number}>`  
  .scroll-table {
    height: ${props => props.headerHeight 
    ? `calc(100vh - ${props.headerHeight}px + 38px)` 
    : `calc(100vh - ${theme.size.header} - 24px)`};
    overflow-y: auto;
    overflow-x: auto;
  
    table {
      margin-top: 0;
      border-collapse: collapse;
    }
    
  
    &::-webkit-scrollbar-track {
      background-color: #F6F6F6;
    }
  
    &::-webkit-scrollbar-thumb {
      border: 4px solid transparent;
      background-clip: content-box;
    }
  
    &::-webkit-scrollbar-corner {
      background-color: #F6F6F6;
    }
    
    thead {
      position: sticky;
      top: 0;
      background-color: white; 
      opacity: 1; 
      z-index: 50;
    }
  
    thead tr th {
      border: none;
      box-shadow: inset 0px 1px 0 ${theme.colors.background}, inset 0px -1px 0 ${theme.colors.background};
    }
  }
`;


import { observer } from "mobx-react-lite";
import { FC, useState } from "react";
import { useRootStore } from "../../stores/storeContext";
import { VQIcon, VQIconSort, VQNoResult, VQTableLoader, VQTableSection, VQTooltip } from "vivaquant-components-library";
import SortListIcon from "../../assets/icons/sort-list.svg";
import { NCMListSort, PAGINATION, SortDirection } from "../../boot/constants";
import { INCMModel } from "../../stores/ncm-list.store";
import { DateUtils, StringsUtils } from "../../services/utils";
import { NcmDeviceStage } from "../../boot/enums/NcmDeviceStage";
import ExecuteIcon from "../../assets/icons/execute.svg";
import ReporteIcon from "../../assets/icons/report.svg";
import { theme } from "../../theme/theme";
import { Permissions } from "../../services/permission.service";
import { WIFormService } from "../../services/wi-form.service";
import { confirmAlert } from "react-confirm-alert";
import _uniqueId from "lodash.uniqueid";

const Comment: FC<{comment: string}> = observer((props: {comment: string}) => {
  const size = 40;
  const [showMore, setShowMore] = useState(false);

  if (!props.comment) {
    return (
      <div className="td-item">{"-"}</div>
    );
  }

  if (props.comment.length > size) {
    if (!showMore) {
      return (
        <div className="td-item" style={{whiteSpace: "pre-line"}}>
          {`${props.comment.substring(0, size)}...   `}
          <span 
            style={{cursor: "pointer", color: theme.colors.blue}}
            onClick={() => {setShowMore(true);}}>
              Read Full Comment
          </span>
        </div>
      );
    } else {
      return (
        <div className="td-item" style={{whiteSpace: "pre-line"}}>
          {props.comment}
          <div 
            style={{cursor: "pointer", color: theme.colors.blue, marginTop: "8px"}}
            onClick={() => {setShowMore(false);}}>
              Hide Full Comment
          </div>
        </div>
      );
    }
    
  } 

  return (
    <div className="td-item" style={{whiteSpace: "pre-line"}}>{props.comment}</div>
  );
});

export const ETNcmListTable: FC = observer(() => {
  const {
    items,
    isLoading,
    setSortBy,
  } = useRootStore().ncmListStore;
  const { setGlobalSpiner, setOpenedRightForm, isOpenedRightForm } = useRootStore().globalStore;
  const { resetForm, openForm, fetchData, id, show, isFormChanged, closeForm } = useRootStore().ncmDispositionStore;
  const { openModal, getReport } = useRootStore().reportStore;
  const { hasPermission } = useRootStore().userStore;

  const isReportRead = hasPermission(Permissions.ReportRead);
  const isNcmDevicesEdit = hasPermission(Permissions.NcmDevicesEdit);

  const sort = (sortBy: string, sortDirection: number) => {
    return setSortBy(sortBy, sortDirection);
  };

  const checkOpenedForm = (action: Function) => {
    if (show) {
      const hide = () => {
        setOpenedRightForm(false);
        closeForm();
        action();
      };
      WIFormService.onClose(isFormChanged, confirmAlert, hide);
    } else {
      action();
    }
  };

  const onEdit = (id: string) => {
    checkOpenedForm(() => {
      resetForm();
      setOpenedRightForm(true);
      setGlobalSpiner(true);
      return fetchData(id).then(() => {
        openForm();
        setGlobalSpiner(false);
      });
    });
    
  };

  const onReport = (id: string, serialNumber: string, date: string) => {
    setGlobalSpiner(true);
    return getReport(id, StringsUtils.reportName(serialNumber, date))
      .then((msg: string) => {
        if (msg) {
          confirmAlert({
            message: msg,
            overlayClassName: "sl-warning-modal",
            buttons: [
              {
                label: "Ok",
                onClick: () => {}
              }
            ]
          });
        } else {
          openModal();
        }
        
        setGlobalSpiner(false);
      });
  };

  const getContent = () => {
    if (isLoading) {
      return (
        <VQTableLoader
          columns={6}
          rows={PAGINATION.ROWS_PER_PAGE}/>
      );
    }

    return (
      <tbody>
        {items.map((item: INCMModel, index: number) => {
          return (
            <tr key={index} className={`${id === item.id ? "tr-selected" : ""}`}>
              <td>
                <div className="td-item">
                  {item.serialNumber || "-"}
                </div>
              </td>
              <td>
                <div className="td-item">
                  {StringsUtils.addNCMWIFullDashes(NcmDeviceStage[item.stage]) || "-"}
                </div>
              </td>
              <td>
                <div className="td-item">
                  {item.failureMode || "-"}
                </div>
              </td>
              <td>
                <div className="td-item">
                  {item.movedToNcmAt ? DateUtils.formatDate(item.movedToNcmAt) : "-"}
                </div>
              </td>
              <td style={{width: "500px"}}>
                <Comment comment={item.comments}></Comment>
              </td>
              <td className="action">
                <div className="td-item">
                  <div className="td-item actions-wrappwer">
                    <div
                      className={`action-item ${isNcmDevicesEdit ? "" : "disabled"}`}
                      onClick={() => { onEdit(item.id); }}>
                      <VQTooltip
                        id={`tooltip-${_uniqueId()}`}
                        class="vq-tooltip"
                        tooltipBlock={<div>Edit</div>}>
                        <VQIcon 
                          icon={ExecuteIcon}
                          padding="0 8px"/>
                      </VQTooltip>
                    </div>
                    <div
                      className={`action-item ${!isOpenedRightForm && isReportRead && item.report ? "" : "disabled"}`}
                      style={{margin: "0"}}
                      onClick={() => { onReport(item.report.id, item.serialNumber, item.report.updatedAt); }}>
                      <VQTooltip
                        id={`tooltip-${_uniqueId()}`}
                        class="vq-tooltip"
                        tooltipBlock={<div>Open Device Report</div>}>
                        <VQIcon 
                          icon={ReporteIcon}
                          padding="0 8px"/>
                      </VQTooltip>
                    </div>
                  </div>
                </div>
              </td>
            </tr>
          );
        })}
      </tbody>
    );
  };

  return (
    <div className="margin-table scroll-table">
      <VQTableSection>
        <thead>
          <tr>
            <th>
              <div className="sort">
                <div className="sort-actions">
                  <div onClick={() => sort(NCMListSort.serialnumber, SortDirection.ASC)}>
                    <VQIconSort icon={SortListIcon}></VQIconSort>
                  </div>
                  <div onClick={() => sort(NCMListSort.serialnumber, SortDirection.DESC)} className="bottom-sort">
                    <VQIconSort icon={SortListIcon}></VQIconSort>
                  </div>
                </div>
                <div className="sort-title">Serial Number</div>
              </div>
            </th>
            <th>
              <div className="sort">
                <div className="sort-actions">
                  <div onClick={() => sort(NCMListSort.stage, SortDirection.ASC)}>
                    <VQIconSort icon={SortListIcon}></VQIconSort>
                  </div>
                  <div onClick={() => sort(NCMListSort.stage, SortDirection.DESC)} className="bottom-sort">
                    <VQIconSort icon={SortListIcon}></VQIconSort>
                  </div>
                </div>
                <div className="sort-title">Stage</div>
              </div>
            </th>
            <th>
              <div className="sort">
                <div className="sort-actions">
                  <div onClick={() => sort(NCMListSort.failuremode, SortDirection.ASC)}>
                    <VQIconSort icon={SortListIcon}></VQIconSort>
                  </div>
                  <div onClick={() => sort(NCMListSort.failuremode, SortDirection.DESC)} className="bottom-sort">
                    <VQIconSort icon={SortListIcon}></VQIconSort>
                  </div>
                </div>
                <div className="sort-title">Failure Mode</div>
              </div>
            </th>
            <th>
              <div className="sort">
                <div className="sort-actions">
                  <div onClick={() => sort(NCMListSort.movedtoncmat, SortDirection.ASC)}>
                    <VQIconSort icon={SortListIcon}></VQIconSort>
                  </div>
                  <div onClick={() => sort(NCMListSort.movedtoncmat, SortDirection.DESC)} className="bottom-sort">
                    <VQIconSort icon={SortListIcon}></VQIconSort>
                  </div>
                </div>
                <div className="sort-title">NCM Date</div>
              </div>
            </th>
            <th style={{width: "500px"}}>NCM Comments</th>
            <th className="action">Actions</th>
          </tr>
        </thead>
        {isLoading && getContent()}
        {items.length > 0 && !isLoading && getContent()}
      </VQTableSection>
      {items.length === 0 && !isLoading && <VQNoResult/> }
    </div>
  );
});

import { observer } from "mobx-react-lite";
import { FC } from "react";
import { useRootStore } from "../../../stores/storeContext";
import { VQCheckbox, VQDynamicFormComponent } from "vivaquant-components-library";
import { 
  WI068ReprogrammingJustificationType, 
  WI068ReprogrammingJustificationTypeLabels 
} from "../../../boot/enums/WI068ReprogrammingJustificationType";
import { ETWIProps } from "../et-wi-forms.props";

export const ETFormWI068Main: FC<ETWIProps> = observer((props: ETWIProps) => {
  const {
    wiRev,
    previousDeviceSerialNumber,
    ncmOrFaNumber,
    reprogrammingJustificationType,
    reprogrammingJustificationTypeErr,
    failureMode,
    comment,
    setReprogrammingJustificationType,
  } = useRootStore().wi068FormStore;

  const handleChange = () => {};

  return (
    <>
      <div className="col-container">
        <VQDynamicFormComponent
          item={wiRev}
          customClass="col-container-el"
          errors={props.errors}
          handleChange={handleChange}
          showError={true}
          disabled={props.isDisabled}
          isFormInvalid={props.formInvalid}></VQDynamicFormComponent>
        <VQDynamicFormComponent
          item={previousDeviceSerialNumber}
          customClass="col-container-el"
          errors={props.errors}
          handleChange={handleChange}
          showError={true}
          disabled={props.isDisabled}
          isFormInvalid={props.formInvalid}></VQDynamicFormComponent>
      </div>
      <div className="col-container">
        <VQDynamicFormComponent
          item={ncmOrFaNumber}
          customClass="col-container-el"
          errors={props.errors}
          handleChange={handleChange}
          showError={true}
          disabled={props.isDisabled}
          isFormInvalid={props.formInvalid}></VQDynamicFormComponent>
        {!failureMode.isLoading && <VQDynamicFormComponent
          item={failureMode}
          customClass="col-container-el"
          errors={props.errors}
          handleChange={handleChange}
          showError={true}
          disabled={props.isDisabled}
          isFormInvalid={props.formInvalid}></VQDynamicFormComponent>}
      </div>
      <div className="label-input">Justification For Reprogramming</div>
      <div className="checkbox-section">
        <VQCheckbox
          handleChange={() => 
            setReprogrammingJustificationType(WI068ReprogrammingJustificationType.MainBoardWasSalvaged)}
          isChecked={reprogrammingJustificationType === WI068ReprogrammingJustificationType.MainBoardWasSalvaged}
          label={WI068ReprogrammingJustificationTypeLabels.MainBoardWasSalvaged}
          disabled={props.isDisabled}
          name={`justification-${WI068ReprogrammingJustificationType[1]}`}
        ></VQCheckbox>
        <VQCheckbox
          handleChange={() => 
            setReprogrammingJustificationType(WI068ReprogrammingJustificationType.DeviceNeedsToBeReprogrammed)}
          isChecked={reprogrammingJustificationType 
            === WI068ReprogrammingJustificationType.DeviceNeedsToBeReprogrammed}
          label={WI068ReprogrammingJustificationTypeLabels.DeviceNeedsToBeReprogrammed}
          disabled={props.isDisabled}
          name={`justification-${WI068ReprogrammingJustificationType[2]}`}
        ></VQCheckbox>
        <VQCheckbox
          handleChange={() => 
            setReprogrammingJustificationType(WI068ReprogrammingJustificationType.Other)}
          isChecked={reprogrammingJustificationType === WI068ReprogrammingJustificationType.Other}
          label={WI068ReprogrammingJustificationTypeLabels.Other}
          disabled={props.isDisabled}
          name={`justification-${WI068ReprogrammingJustificationType[3]}`}
        ></VQCheckbox>
        {reprogrammingJustificationTypeErr && <div className="invalid-text-error">
          {reprogrammingJustificationTypeErr}
        </div>}
      </div>
      <VQDynamicFormComponent
        item={comment}
        errors={props.errors}
        handleChange={handleChange}
        showError={true}
        disabled={props.isDisabled}
        isFormInvalid={props.formInvalid}></VQDynamicFormComponent>
    </>
  );
});

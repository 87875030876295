import { observer } from "mobx-react-lite";
import { useRootStore } from "../../stores/storeContext";
import { FC, useEffect, useState } from "react";
import { InProgresPageWrapper } from "./InProgress.presents";
import { ETInProgressListHeader } from "../../components/et-in-progress-list-header";
import { ETInProgressListTable } from "../../components/et-in-progress-list-table";
import { ETRightForm } from "../../components/et-right-form";
import { ETFormWI041 } from "../../components/et-wi-forms/et-form-wi041";
import { ETFormWI068 } from "../../components/et-wi-forms/et-form-wi068";
import { ETFormWI056 } from "../../components/et-wi-forms/et-form-wi056";
import { ETFormWI083 } from "../../components/et-wi-forms/et-form-wi083";
import { ETFormWI048 } from "../../components/et-wi-forms/et-form-wi048";
import { ETFormWI049 } from "../../components/et-wi-forms/et-form-wi049";
import { ETFormWI057 } from "../../components/et-wi-forms/et-form-wi057";
import { ETFormWI050 } from "../../components/et-wi-forms/et-form-wi050";
import { ETFormWI058 } from "../../components/et-wi-forms/et-form-wi058";
import { ETFormWI051 } from "../../components/et-wi-forms/et-form-wi051";
import { ETFormWI119 } from "../../components/et-wi-forms/et-form-wi119";
import { ETFormWI059ER } from "../../components/et-wi-forms/et-form-wi059er";
import { ETReportModal } from "../../components/et-report-modal";

export const InProgresPage: FC = observer(() => {
  const {
    getTotalCount,
  } = useRootStore().inProgressListStore;
  const { setGlobalSpiner, isOpenedRightForm } = useRootStore().globalStore;
  const { show: showReport } = useRootStore().reportStore;

  const { show: showWI041, serialNumber : serialNumberWI041 } = useRootStore().wi041FormStore;
  const { show: showWI068, serialNumber : serialNumberWI068 } = useRootStore().wi068FormStore;
  const { show: showWI056, serialNumber : serialNumberWI056 } = useRootStore().wi056FormStore;
  const { show: showWI083, serialNumber : serialNumberWI083 } = useRootStore().wi083FormStore;
  const { show: showWI048, serialNumber : serialNumberWI048 } = useRootStore().wi048FormStore;
  const { show: showWI049, serialNumber : serialNumberWI049 } = useRootStore().wi049FormStore;
  const { show: showWI057, serialNumber : serialNumberWI057 } = useRootStore().wi057FormStore;
  const { show: showWI050, serialNumber : serialNumberWI050 } = useRootStore().wi050FormStore;
  const { show: showWI058, serialNumber : serialNumberWI058 } = useRootStore().wi058FormStore;
  const { show: showWI051, serialNumber : serialNumberWI051 } = useRootStore().wi051FormStore;
  const { show: showWI119, serialNumber : serialNumberWI119 } = useRootStore().wi119FormStore;
  const { show: showWI059ER, serialNumber : serialNumberWI059ER } = useRootStore().wi059ERFormStore;

  const [headerHeight, setHeaderHeight] = useState(0);

  const updateSize = () => {
    let elHeight = document.querySelector(".margin-table table thead")?.getBoundingClientRect()?.bottom || 1;
    if (elHeight !== headerHeight) {
      setHeaderHeight(elHeight);
    }
  };

  useEffect(() => { 
    window.addEventListener("resize", updateSize);
    return () => {
      window.removeEventListener("resize", updateSize);
    };
  }, []);

  useEffect(() => {
    updateSize();
  });

  useEffect(() => {
    setGlobalSpiner(true);
    getTotalCount().then(() => { setGlobalSpiner(false); });
  }, []);
  return (
    <>
      <InProgresPageWrapper className={`${isOpenedRightForm ? "form-margin" : ""}`} headerHeight={headerHeight}>
        <ETInProgressListHeader></ETInProgressListHeader>
        <ETInProgressListTable></ETInProgressListTable>
      </InProgresPageWrapper>
      
      {showWI041 && <ETRightForm 
        title={"WI-041"}
        subTitle={`Device Serial Number: ${serialNumberWI041} `}>
        <ETFormWI041></ETFormWI041>
      </ETRightForm>}

      {showWI068 && <ETRightForm 
        title={"WI-068"}
        subTitle={`Device Serial Number: ${serialNumberWI068} `}>
        <ETFormWI068></ETFormWI068>
      </ETRightForm>}

      {showWI056 && <ETRightForm 
        title={"WI-056"}
        subTitle={`Device Serial Number: ${serialNumberWI056} `}>
        <ETFormWI056></ETFormWI056>
      </ETRightForm>}

      {showWI083 && <ETRightForm 
        title={"WI-083"}
        subTitle={`Device Serial Number: ${serialNumberWI083} `}>
        <ETFormWI083></ETFormWI083>
      </ETRightForm>}
      
      {showWI048 && <ETRightForm 
        title={"WI-048"}
        subTitle={`Device Serial Number: ${serialNumberWI048} `}>
        <ETFormWI048></ETFormWI048>
      </ETRightForm>}
      
      {showWI049 && <ETRightForm 
        title={"WI-049"}
        subTitle={`Device Serial Number: ${serialNumberWI049} `}>
        <ETFormWI049></ETFormWI049>
      </ETRightForm>}
      
      {showWI057 && <ETRightForm 
        title={"WI-057"}
        subTitle={`Device Serial Number: ${serialNumberWI057} `}>
        <ETFormWI057></ETFormWI057>
      </ETRightForm>}

      {showWI050 && <ETRightForm 
        title={"WI-050"}
        subTitle={`Device Serial Number: ${serialNumberWI050} `}>
        <ETFormWI050></ETFormWI050>
      </ETRightForm>}

      {showWI058 && <ETRightForm 
        title={"WI-058"}
        subTitle={`Device Serial Number: ${serialNumberWI058} `}>
        <ETFormWI058></ETFormWI058>
      </ETRightForm>}

      {showWI051 && <ETRightForm 
        title={"WI-051"}
        subTitle={`Device Serial Number: ${serialNumberWI051} `}>
        <ETFormWI051></ETFormWI051>
      </ETRightForm>}

      {showWI119 && <ETRightForm 
        title={"WI-119"}
        subTitle={`Device Serial Number: ${serialNumberWI119} `}>
        <ETFormWI119></ETFormWI119>
      </ETRightForm>}

      {showWI059ER && <ETRightForm 
        title={"WI-059-ER "}
        subTitle={`Device Serial Number: ${serialNumberWI059ER } `}>
        <ETFormWI059ER ></ETFormWI059ER >
      </ETRightForm>}

      {showReport && <ETReportModal></ETReportModal>}

    </>
  );
});
import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-confirm-alert/src/react-confirm-alert.css";
import "spin.js/spin.css";
import "react-circular-progressbar/dist/styles.css";

import "./css/reboot.css";
import "./css/index.css";
import { createRoot } from "react-dom/client";
import { App } from "./boot/App";

const container = document.getElementById("root");
const root = createRoot(container!);

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

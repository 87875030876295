import { observer } from "mobx-react-lite";
import { FC, useEffect, useState } from "react";
import { useRootStore } from "../../stores/storeContext";
import { NCMPageWrapper } from "./NCM.presents";
import { ETNcmListHeader } from "../../components/et-ncm-list-header";
import { ETNcmListTable } from "../../components/et-ncm-list-table";
import { ETRightForm } from "../../components/et-right-form";
import { ETNCMDisposition } from "../../components/et-ncm-disposition";
import { ETReportModal } from "../../components/et-report-modal";

export const NCMPage: FC = observer(() => {
  const { getTotalCount } = useRootStore().ncmListStore;
  const { setGlobalSpiner, isOpenedRightForm } = useRootStore().globalStore;
  const { show: showReport } = useRootStore().reportStore;

  const { show, serialNumber } = useRootStore().ncmDispositionStore;

  const [headerHeight, setHeaderHeight] = useState(0);

  const updateSize = () => {
    let elHeight = document.querySelector(".margin-table table thead")?.getBoundingClientRect()?.bottom || 1;
    if (elHeight !== headerHeight) {
      setHeaderHeight(elHeight);
    }
  };

  useEffect(() => { 
    window.addEventListener("resize", updateSize);
    return () => {
      window.removeEventListener("resize", updateSize);
    };
  }, []);

  useEffect(() => {
    updateSize();
  });

  useEffect(() => {
    setGlobalSpiner(true);
    getTotalCount().then(() => { setGlobalSpiner(false); });
  }, []);
  return (
    <>
      <NCMPageWrapper className={`${isOpenedRightForm ? "form-margin" : ""}`}  headerHeight={headerHeight}>
        <ETNcmListHeader></ETNcmListHeader>
        <ETNcmListTable></ETNcmListTable>
      </NCMPageWrapper>

      {show && <ETRightForm 
        title={"NCM Disposition"}
        subTitle={`Device Serial Number: ${serialNumber} `}>
        <ETNCMDisposition></ETNCMDisposition>
      </ETRightForm>}

      {showReport && <ETReportModal></ETReportModal>}
    </>
  );
});

import { observer } from "mobx-react-lite";
import { FC, useEffect, useState } from "react";
import { AdminRouteConfigurationPageWrapper } from "./RouteConfiguration.presents";
import { useRootStore } from "../../../stores/storeContext";
import { ETadminRouteConfigurationListHeader } from "../../../components/et-admin-route-configuration-list-header";
import { ETAdminRouteConfigurationListTable } from "../../../components/et-admin-route-configuration-list-table";
import { ETRightForm } from "../../../components/et-right-form";
import { ETAdminRouteConfigurationAdd } from "../../../components/et-admin-route-configuration-add";

export const AdminRouteConfigurationPage: FC = observer(() => {
  const {
    getTotalCount
  } = useRootStore().adminRouteConfigurationListStore;
  const { setGlobalSpiner } = useRootStore().globalStore;
  const { show, isEdit } = useRootStore().adminRouteConfigurationAddStore;

  const [headerHeight, setHeaderHeight] = useState(0);

  const updateSize = () => {
    let elHeight = document.querySelector(".margin-table table thead")?.getBoundingClientRect()?.bottom || 1;
    if (elHeight !== headerHeight) {
      setHeaderHeight(elHeight);
    }
  };

  useEffect(() => { 
    window.addEventListener("resize", updateSize);
    return () => {
      window.removeEventListener("resize", updateSize);
    };
  }, []);

  useEffect(() => {
    updateSize();
  });

  useEffect(() => {
    setGlobalSpiner(true);
    getTotalCount().then(() => { setGlobalSpiner(false); });
  }, []);
  
  return (
    <>
      <AdminRouteConfigurationPageWrapper headerHeight={headerHeight}>
        <ETadminRouteConfigurationListHeader></ETadminRouteConfigurationListHeader>
        <ETAdminRouteConfigurationListTable></ETAdminRouteConfigurationListTable>
      </AdminRouteConfigurationPageWrapper>
      {show && <ETRightForm 
        title={`${isEdit ? "Edit" : "Add"} Route Configuration`}
      >
        <ETAdminRouteConfigurationAdd></ETAdminRouteConfigurationAdd>
      </ETRightForm>}
    </>
    
  );
});

import styled from "styled-components";
import { theme } from "../../theme/theme";

export const ETNCMDispositionWrapper = styled.div`
  .ncm-number-el {
    position: relative;

    input {
      padding-left: 62px;
    }
  }
  .ncm-number-el::before {
    content: "NCMR-";
    position: absolute;
    color: ${theme.colors.placeholder};
    top: 53px;
    left: 16px;
    display: block;
    opacity: 0.6;
    z-index: 2;
    font-size: 14px;
  }

  .disabled {
    opacity: 0.5;
  }
`;